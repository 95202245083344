@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


label{color: #FFF;}
/* Hide the default file input */
.upload {
  display: none !important;
}
.uploadField{display: inline-block;}

input.uploadField{width: 100%;}

/* Style the custom button to trigger the file input */
.upload-label {
  background-color: #8a2be2; /* Button background color */
  color: white; /* Button text color */
  border: none;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s ease-in-out;
  padding: 10px 20px;margin-bottom: 15px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 4px;
}

/* Style the label to resemble a button */
.upload-label:hover {
  background-color: #ffd700; /* Button background color on hover */
}

/* Style the label to look like a button when the file input has focus */
.upload:focus + .upload-label {
  outline: 2px solid #007bff; /* Add an outline when focused */
}

/* Default button styles */
button[type="submit"] {
  background-color: #8a2be2; /* Majestic purple */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 700;
  font-size: 15px;
  transition: background-color 0.3s ease-in-out;
}

/* Add hover effect to the button */
button[type="submit"]:hover {
  background-color: #ffd700; /* Golden yellow */
}

body{margin: 0;font-family: 'Poppins', sans-serif;}
h1{margin: 0;}
button {
  background-color:transparent;
  border: 0;
  display: inline;
  cursor: pointer;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

#uploadForm .description{margin-top: 40px;color:#fff;z-index:3;position: relative;}

#uploadForm p{color:#fff;z-index: 3;position: relative;}

#header{padding:2%;background-color:#000;color: #fff !important;}

#uploadForm {
  position: relative; /* Add this to make the ::before pseudo-element relative to the form */
  text-align: left;
  width: 35%;
  padding: 2%;
  float: left;
  height:100vh;
  z-index: 2;
  background-image: url("./img/13bae28bb5e96ec8934583855d16be3d.jpeg");
  background-size: cover;
}

#uploadForm * {
  z-index: 3;
}

#uploadForm::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 0; /* Place the overlay behind the form */ /* Adjust the alpha (last value) to control the opacity of the black overlay */
}

  .custom-progress-bar-container {
    width: 100%;
    height: 20px; /* Adjust the height as needed */
    background-color: #ccc; /* Background color for the progress bar container */
    color: #fff;
    z-index: 3;
    position: relative;
    border-radius: 4px; /* Rounded corners for the container */
    margin-top: 10px; /* Add any desired margins */
  }
  
  .custom-progress-bar {
    height: 100%;
    width: 0;
    z-index: 3;
    position: relative;
    background-color: #007bff; /* Color of the progress bar */
    border-radius: 4px; /* Rounded corners for the progress bar */
    transition: width 0.3s; /* Smooth animation for progress changes */
  }

#audioList{width: 57%;padding: 2%;float: left;max-height:800px;overflow: scroll;}

.audioInfo{margin: 20px 0 10px 0;}

ul{list-style: none;}


/*MEDIA*/
@media (max-width: 600px) {
  .description{display: none;}

  #uploadForm{text-align: center;width: 92%;padding: 4%;height: auto;}

  #audioList{ width: 96%;padding: 2%;float: left;max-height:800px;overflow: scroll;}
}


.error{color: red;}

/* Style the form container */
form {
  display: flex;
  flex-direction: column;
  align-items: left;

}

.login-wrapper p {color: #000 !important;}

/* Style the input fields */
input[type="file"],
input[type="text"] {
padding: 2px 0 2px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}


input.update{margin-right: 10px;}







.menuLink{margin-right: 13px;}


#logo{display: inline-block;}








